<template>
    <div>
        <p>product</p>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>

</style>